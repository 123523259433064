import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';
import axios from 'axios';
import { BrowserTracing } from '@sentry/tracing';
import { BLOCKED_NAVIGATION_ERROR_MESSAGE } from './routerWindow';

window.React = React;

// やむを得ずグローバル変数を使う必要がある場合
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __atw: {
      merchant: string;
      param: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result_id: string;
        verify: number;
      };
    }[]; // AccessTradeCVタグ用
    afblpcvCvConf: {
      siteId: string;
      commitData: {
        pid: string;
        u: number;
      };
    }[]; // AfibCVタグ用
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lag: any; // LinkACVタグ用
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
  environment:
    // 各環境におけるSentryのURLはWiki(https://samurai-7s.backlog.com/alias/wiki/1777803)に記載の通り。
    // production以外は、同じSentry上に送信されるため environment で絞り込めるようにしています。
    process.env.REACT_APP_ENV !== 'production'
      ? (process.env.REACT_APP_ENV ?? undefined)
      : undefined,
  integrations:
    process.env.REACT_APP_ENV === 'local'
      ? // Warning: ローカルからSentryへ送信する場合は、tracingOriginsの設定を行わないとエラーが発生する
        [new BrowserTracing({ tracingOrigins: ['*'] })]
      : [new BrowserTracing()],
  // https://sentry.io/organizations/samurai-b6/issues/2651633161/?project=5268715&query=is%3Aunresolved
  // TODO 上記のエラーでSentryの全ての容量が使われてしまう現象が発生しているので、一時的にサンプルレートを10%に。
  // ignoreErrorsで追加したcontentWindowのエラーが発生しないことを確認したら、10%から100%へ戻す
  // https://samurai-7s.backlog.com/board/LMS?selectedIssueKey=LMS-1682
  // https://docs.sentry.io/platforms/javascript/
  tracesSampleRate: 0.1,
  // https://sentry.io/organizations/samurai-b6/issues/2568113231/?project=5268715&query=is%3Aignored
  // https://sentry.io/organizations/samurai-b6/issues/2138543401/?project=5268715&query=is%3Aignored
  // 上記のエラーに対して、以下の記事のように対応を行ったが、2重の対応としてignoreErrorsを追加
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523

  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Network Error',
    'FirebaseError',
    'InvalidStateError',
    // https://samurai-7s.backlog.com/board/LMS?selectedIssueKey=LMS-1685
    // 上記のチケットに記載した通り、こちらの対応でどうしようもないため、FirebaseErrorは無視する。
    // 要約すると、以下のエラーのみだったので無視する。個別エラーを記載。
    //  * オフラインだった場合のエラー
    //  * 2021/09/17現在にIE11/Edge/Firefoxがプライベートモードだった場合にブラウザのIndexedDBに書き込めない仕様なので発生するエラー
    'Remote Config: Error thrown when reading from storage',
    'Remote Config: Fetch client failed to connect to a network',
    'Installations: Could not process request. Application offline',
    'このサーバの証明書は無効です',
    'Remote Config: Error thrown when opening storage',
    'Remote Config: Error thrown when writing to storage',
    // 'FirebaseError', //上記が効かないようだったら、こちらのFirebaseErrorのコメントアウトを解除して効くようにする。
    'UnhandledRejection', //TODO Promiseでcatchしていない箇所で発生 https://samurai-7s.backlog.com/board/LMS?selectedIssueKey=LMS-1684
    'Request failed with status code 401', // TODO https://samurai-7s.backlog.com/board/LMS?selectedIssueKey=LMS-1653
    'Failed to load Stripe.js',
    'Remote Config: Fetch server returned an HTTP error status. HTTP status: 403. (remoteconfig/fetch-status).',
    'Installations: Firebase Installation is not registered.',
    'Remote Config: The config fetch request timed out.  Configure timeout using "fetchTimeoutMillis" SDK setting. (remoteconfig/fetch-timeout).',
    "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.\n",
    "Can't find variable: IntersectionObserver", // iOS12.1以下で発生。サポート切って問題ないことを確認済み。
    'The operation is insecure.', // FireFoxでCookieの設定によっては発生。
    // Chrome 103以降では発生しないようなので様子を見て不要になったら削除する(以下2つセット)
    'Possible side-effect in debug-evaluate',
    'Unexpected end of input',
    'SecurityError: Blocked a frame with origin "https://terakoya.sejuku.net" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
    "can't access dead object",
    "null is not an object (evaluating 'this.h.src')",
    "Cannot read properties of null (reading 'sessionId')",
    // TODO 上記が効かない場合にひとまずエラーを止めるために以下の行のTypeErrorをOnにする  https://samurai-7s.backlog.com/board/LMS?selectedIssueKey=LMS-1682&mode=edit
    // 'TypeError',
    "Failed to execute 'pushState' on 'History'",
    'jf_ChgDns is not defined',
    BLOCKED_NAVIGATION_ERROR_MESSAGE, // https://samurai-7s.backlog.com/view/LMS-5280
    'undefined is not an object (evaluating \'r["@context"].toLowerCase\')', // https://samurai-7s.backlog.com/view/LMS-5479
    'パスワード試行回数が超過しました',
    "A history state object with URL 'file:///C:/'", // https://dev.azure.com/sejuku/SYS-TERAKOYA/_boards/board/t/mantoman/Issues/?workitem=12076
    "Cannot read properties of undefined (reading 'firefoxSample')", // https://dev.azure.com/sejuku/SYS-TERAKOYA/_backlogs/backlog/mantoman/Issues/?workitem=12360
    'A request was aborted, for example through a call to IDBTransaction.abort.', // https://dev.azure.com/sejuku/SYS-TERAKOYA/_boards/board/t/mantoman/Issues/?workitem=12376
  ],

  denyUrls: [
    // 不明 https://samurai-7s.slack.com/archives/CK6PUCPLL/p1655347703085799
    /cdn\.nmgassets\.com/i,
    // 時折、エラーになってしまうので除外。GoogleTagManagerで設定中。運営会社: https://zaoric.com/
    /best-movie100\.com/i,
    // https://samurai-7s.backlog.com/view/LMS-4966
    /cdn\.mouseflow\.com/i,
    // #12049
    /js\.intercomcdn\.com/i,
    // #12094
    /www\.youtube\.com/i,
    // #12113
    /cdn\.taboola\.com/i,
    // #12177
    /static\.karte\.io/i,
    // #12205, #12214
    /analytics\.tiktok\.com/i,
    // #12834
    /www\.clarity\.ms/i,
  ],
});

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

// Set axios default endpoint
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// TODO: AB#15046 本番障害のため、一時的にコメントアウト
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

//E2Eテストなどで必要がないのに読み込むタイミングがあるので、そちらを無効化するために追加
// TODO: AB#15046 本番障害のため、一時的にコメントアウト
// if (checkEnableFirebaseService()) {
//   firebase.initializeApp(firebaseConfig);
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
