import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';
import {
  ClaimDetailFragment as ClaimDetail,
  useDeleteClaimDetailForInstructorMutation,
} from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { defaultErrorMessage } from '../../const/ErrorMessage';

interface DeleteModalProps {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  claimDetail?: ClaimDetail;
  refetch: (date: Date, claimCategoryId?: number, studentId?: number) => void;
  month: Date;
  claimCategoryId?: number;
  studentId?: number;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  toggle,
  claimDetail,
  refetch,
  month,
  claimCategoryId,
  studentId,
  ...props
}) => {
  const insertCommaDelimiter = (numberWithoutComma: string) =>
    numberWithoutComma.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  const zeroPadding = (num: number) => `0${num}`.substr(-2);
  const formatDate = (date: Date) =>
    `${date.getFullYear()}/${zeroPadding(date.getMonth() + 1)}/${zeroPadding(date.getDate())}`;

  const { showToast } = useToastsContext();

  /**
   * 削除処理
   */
  const [deleteClaim] = useDeleteClaimDetailForInstructorMutation();
  const submitDeleteClaim = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!claimDetail) return;

      try {
        await deleteClaim({
          variables: {
            id: claimDetail.id,
          },
        });
        await refetch(month, claimCategoryId, studentId);
      } catch {
        showToast(1, defaultErrorMessage);
        return;
      }
      toggle(false);
      showToast(0, '請求申請詳細を削除しました。');
    }, [claimDetail, toggle, showToast, deleteClaim, refetch, month, claimCategoryId, studentId]),
  );

  const cancel = () => toggle(false);

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={() => toggle(false)}
      loading={props.loading}
      header={'請求申請 削除'}
      footer={
        <Buttons>
          <Button onClick={cancel} gray>
            キャンセル
          </Button>
          <Button onClick={submitDeleteClaim}>削除</Button>
        </Buttons>
      }
      width={'550px'}
    >
      <ModalContent>
        <ConfirmSentence>下記項目を削除します。よろしいですか？</ConfirmSentence>
        <Label className="date">
          <span>日付:</span>
          <p>{claimDetail?.date ? formatDate(new Date(claimDetail.date)) : ''}</p>
        </Label>
        <Label className="category">
          <span>カテゴリー:</span>
          <p>{claimDetail?.claimCategory?.name}</p>
        </Label>
        <Label className="student">
          <span>受講生:</span>
          <p>
            {claimDetail?.course?.student?.status !== 0
              ? claimDetail?.course?.student.user?.maskedPersonalInfo?.name
              : '-'}
          </p>
        </Label>
        <Label className="lesson">
          <span>レッスン:</span>
          <p>{claimDetail?.lesson?.title ? claimDetail.lesson.title : '-'}</p>
        </Label>
        <Label className="amount">
          <span>時間（分）・数量:</span>
          <p>{claimDetail?.amount ? claimDetail?.amount : '-'}</p>
        </Label>
        <Label className="unit_price">
          <span>
            単価（円/時）<span className="tax_included_annotation">※税込</span>:
          </span>
          <p>{insertCommaDelimiter(String(claimDetail?.unitPrice)) + '円'}</p>
        </Label>
        <Label className="additional_payment">
          <span>追加費用:</span>
          <p>
            {claimDetail?.additionalPayment
              ? insertCommaDelimiter(String(claimDetail?.additionalPayment)) + ' 円'
              : '-'}
          </p>
        </Label>
        <Label className="remark">
          <span>備考:</span>
          <p>{claimDetail?.remark}</p>
        </Label>
      </ModalContent>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const ModalContent = styled.div`
  padding: 1.25rem;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const ConfirmSentence = styled.p`
  text-align: center;
  margin-bottom: 1.25rem;
`;
const Label = styled.div`
  margin-bottom: 20px;

  span {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;

    .tax_included_annotation {
      font-size: 0.8rem;
      font-weight: normal;
      padding-left: 0.5rem;
      display: inline;
    }
  }

  p {
    width: 100%;
    word-break: keep-all;
  }
`;
