import {
  NOT_REVIEWED,
  REVIEWED,
  REVIEW_COMMENT_PASSED,
  REVIEW_COMMENT_FAILED,
} from '../const/PracticeSubmit';
import {
  PracticeSubmitDetailReviewStatus,
  InstructorPracticeSubmitSearchType,
  PracticeSubmitSearchType,
  PracticeSubmitSortKey,
  PracticeSubmitDetail,
} from '../gen/graphql';

export const PRACTICE_SUBMIT_COMMENT_MAX_LENGTH = 16383;

export const getSearchType = (type: string): PracticeSubmitSearchType => {
  switch (type) {
    case 'mine':
      return PracticeSubmitSearchType.Mine;
    case 'everyone':
      return PracticeSubmitSearchType.Everyone;
    case 'my_draft':
      return PracticeSubmitSearchType.MyDraft;
    default:
      return PracticeSubmitSearchType.Mine;
  }
};

export const getInstructorSearchType = (type: string): InstructorPracticeSubmitSearchType => {
  switch (type) {
    case 'all':
      return InstructorPracticeSubmitSearchType.All;
    case 'my_students':
      return InstructorPracticeSubmitSearchType.MyStudents;
    case 'reviewed':
      return InstructorPracticeSubmitSearchType.Reviewed;
    default:
      return InstructorPracticeSubmitSearchType.All;
  }
};

export const getSortKey = (value: string): PracticeSubmitSortKey =>
  Object.values(PracticeSubmitSortKey).find((enumValue) => enumValue === value) ??
  PracticeSubmitSortKey.CreatedAt;

export const getReviewed = (value: number): boolean | undefined => {
  if (value === REVIEWED) {
    return true;
  }
  if (value === NOT_REVIEWED) {
    return false;
  }

  return undefined;
};

export const getReviewStatus = (value: string): PracticeSubmitDetailReviewStatus | undefined =>
  // Draft以外
  Object.values(PracticeSubmitDetailReviewStatus).find(
    (enumValue) => enumValue === value && enumValue !== PracticeSubmitDetailReviewStatus.Draft,
  );

export const isDraft = (value?: PracticeSubmitDetailReviewStatus): boolean =>
  value === PracticeSubmitDetailReviewStatus.Draft;

export const isFailed = (value?: PracticeSubmitDetailReviewStatus): boolean =>
  value === PracticeSubmitDetailReviewStatus.Failed;

export const isPassed = (value?: PracticeSubmitDetailReviewStatus): boolean =>
  value === PracticeSubmitDetailReviewStatus.Passed;

export const isWaiting = (value?: PracticeSubmitDetailReviewStatus): boolean =>
  value === PracticeSubmitDetailReviewStatus.WaitingForInstructorReview;

export const isReviewed = (value?: PracticeSubmitDetailReviewStatus): boolean =>
  value === PracticeSubmitDetailReviewStatus.Passed ||
  value === PracticeSubmitDetailReviewStatus.Failed;

export const getRecentPracticeSubmitStatus = (
  practiceSubmitDetails: Pick<PracticeSubmitDetail, 'reviewStatus'>[] | null | undefined,
): PracticeSubmitDetailReviewStatus | undefined => {
  if (practiceSubmitDetails?.length) return practiceSubmitDetails[0].reviewStatus;
  return undefined;
};

export const getRecentPracticeSubmitStatusWithoutDraft = (
  practiceSubmitDetails: Pick<PracticeSubmitDetail, 'reviewStatus'>[] | null | undefined,
): PracticeSubmitDetailReviewStatus | undefined => {
  if (practiceSubmitDetails) {
    for (const { reviewStatus } of practiceSubmitDetails) {
      if (!isDraft(reviewStatus)) return reviewStatus;
    }
  }
  return undefined;
};

export const checkIncludeReviewed = (
  practiceSubmitDetails: Pick<PracticeSubmitDetail, 'reviewStatus'>[] | null | undefined,
): boolean => {
  if (practiceSubmitDetails) {
    for (const { reviewStatus } of practiceSubmitDetails) {
      if (isReviewed(reviewStatus)) return true;
    }
  }
  return false;
};

export const isCommentLengthValid = (length: number): boolean => {
  return 0 < length && length <= PRACTICE_SUBMIT_COMMENT_MAX_LENGTH;
};

export const generateReviewComment = (
  baseComment: string | null | undefined,
  status: PracticeSubmitDetailReviewStatus,
): string => {
  if (!baseComment) return '';

  if (status === PracticeSubmitDetailReviewStatus.Passed) {
    return baseComment + REVIEW_COMMENT_PASSED;
  }
  if (status === PracticeSubmitDetailReviewStatus.Failed) {
    return baseComment + REVIEW_COMMENT_FAILED;
  }

  return baseComment;
};
