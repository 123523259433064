import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPracticeSubmitQuery, useRePracticeSubmitMutation } from '../../../gen/graphql';
import { NotFoundPage } from '../public/NotFound';
import {
  PracticeSubmitInputArea,
  PracticeSubmitResponseProps,
} from '../../organisms/PracticeSubmitInputArea';
import { Loader } from '../../molecules/Loader';
import { BasicLayout } from '../../templates/BasicLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { useUser } from '../../../redux/user/useUser';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { PracticeSubmitBreadcrumb } from '../../organisms/PracticeSubmitBreadcrumb';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const ReCreatePracticeSubmitContainer = (): JSX.Element => {
  const pathID = useParams<{ practice_submit_id: string }>().practice_submit_id;
  if (!pathID) return NotFoundPage;

  return <ReCreatePracticeSubmit practiceSubmitID={pathID} />;
};

interface ReCreatePracticeSubmitProps {
  practiceSubmitID: string;
}

const ReCreatePracticeSubmit: React.FC<ReCreatePracticeSubmitProps> = (props): JSX.Element => {
  const metaTitle = `課題再提出フォーム | ${LOWER_META_TITLE}`;
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  const { permissionCheck } = useUser();

  const { data: practiceSubmitData, loading: practiceSubmitLoading } = useGetPracticeSubmitQuery({
    variables: {
      id: props.practiceSubmitID,
    },
  });
  const practice = practiceSubmitData?.getPracticeSubmit?.practice;
  const isInstructorReviewRequested =
    practiceSubmitData?.getPracticeSubmit?.isInstructorReviewRequested;

  const [rePracticeSubmit] = useRePracticeSubmitMutation();
  const draft = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await rePracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            url: url,
            content: comment,
            isDraft: true,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (
        !data ||
        !data.rePracticeSubmit ||
        !data.rePracticeSubmit.practiceSubmitDetails ||
        data.rePracticeSubmit.practiceSubmitDetails.length === 0
      ) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.rePracticeSubmit.id,
        practiceSubmitDetailID: data.rePracticeSubmit.practiceSubmitDetails[0].id,
      };
    },
    [rePracticeSubmit, props.practiceSubmitID],
  );
  const submit = useCallback(
    async (
      url: string,
      comment?: string,
      requestsInstructorReview?: boolean,
    ): Promise<PracticeSubmitResponseProps> => {
      const { data } = await rePracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            url: url,
            content: comment,
            isDraft: false,
            requestsInstructorReview: requestsInstructorReview,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (!data || !data.rePracticeSubmit) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.rePracticeSubmit.id,
        practiceSubmitDetailID: '',
      };
    },
    [rePracticeSubmit, props.practiceSubmitID],
  );

  return (
    <>
      <Loader display={practiceSubmitLoading} />
      <BasicLayout
        pageTitle="課題再提出フォーム"
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <PracticeSubmitBreadcrumb
            permission={permissionCheck(
              FunctionType.ProgramForInstructorAndCoach,
              PermissionType.Read,
            )}
            programTitle={practice?.program.title}
            programID={practice?.programID}
            practiceTitle={practice?.title}
            practiceID={practice?.id}
          />
          {practice && isInstructorReviewRequested !== undefined ? (
            <PracticeSubmitInputArea
              practice={practice}
              draft={draft}
              submit={submit}
              isInstructorReviewRequested={isInstructorReviewRequested}
            />
          ) : (
            <EmptyBlock title="提出できる課題がありません" />
          )}
        </PageWrapper>
      </BasicLayout>
    </>
  );
};
