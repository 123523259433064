import React, { useState } from 'react';
import media from 'styled-media-query';
import styled from 'styled-components';

import { format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';

import { EmptyBlock } from '../molecules/EmptyBlock';

import ScrollHint from '../../static/image/scroll_hint.png';

import {
  useGetClaimAndDetailsForInstructorQuery,
  ClaimDetailFragment as ClaimDetail,
  ClaimFragment as Claim,
} from '../../gen/graphql';
import { insertCommaDelimiter } from '../../utils/common';
import { crop } from '../../utils/String';

// 日付表示系処理
const formatDateWithDay = (d: string): string => format(parseISO(d), 'yyyy/MM/dd');
const formatDays = (d: string): string => format(parseISO(d), 'E', { locale: ja });
const formatHourMinuteSecond = (d: string): string => format(parseISO(d), 'hh:mm:ss');

interface ClaimTableProps {
  claimData: ReturnType<typeof useGetClaimAndDetailsForInstructorQuery>['data'];
  isEditable: (claim: Claim | undefined) => boolean;
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentClaimDetail: React.Dispatch<React.SetStateAction<ClaimDetail | undefined>>;
}

/**
 * 請求一覧
 */
export const ClaimTable: React.FC<ClaimTableProps> = ({
  claimData,
  isEditable,
  setEditModalOpen,
  setDeleteModalOpen,
  setCurrentClaimDetail,
}) => {
  const [displayScrollHint, setDisplayScrollHint] = useState(true);

  const claim = claimData?.claimForInstructor ?? undefined;
  const claimDetails = claimData?.claimDetailsForInstructor ?? [];

  if (claimDetails.length === 0) {
    return <EmptyContent title="現在表示できる請求情報はありません" />;
  }

  return (
    <TableContainer
      onScroll={() => setDisplayScrollHint(false)}
      displayScrollHint={displayScrollHint}
    >
      <TableWrapper className="list">
        <Dl>
          <Dt>日付</Dt>
          <Dt>カテゴリー</Dt>
          <Dt>受講生</Dt>
          <Dt>専属レッスン</Dt>
          <Dt>単発レッスン開始時間</Dt>
          <Dt>時間（分）・件数・数量・文字数</Dt>
          <Dt>
            <span>
              単価（円/時）・単価（円/件）・単価（円/文字）
              <span className="tax_included_annotation">※全て税込</span>
            </span>
          </Dt>
          <Dt>追加費用</Dt>
          <Dt>小計</Dt>
          <Dt>備考</Dt>
          <Dt>編集</Dt>
          <Dt>削除</Dt>
        </Dl>
        {claimDetails.map((claimDetail) => {
          return (
            <Dl key={claimDetail.id}>
              <Dd>{`${formatDateWithDay(claimDetail.date)}（${formatDays(claimDetail.date)}）`}</Dd>
              <Dd>{claimDetail?.claimCategory?.name}</Dd>
              <Dd>{claimDetail?.course?.student?.user?.maskedPersonalInfo?.name ?? '-'}</Dd>
              <Dd>{claimDetail?.lesson?.title ?? '-'}</Dd>
              <Dd>
                {claimDetail?.spotLesson?.startAt
                  ? formatHourMinuteSecond(claimDetail.spotLesson.startAt)
                  : '-'}
              </Dd>
              <Dd>{claimDetail?.amount ?? '-'}</Dd>
              <Dd>{insertCommaDelimiter(String(claimDetail.unitPrice))} 円</Dd>
              <Dd>
                {claimDetail.additionalPayment
                  ? insertCommaDelimiter(String(claimDetail.additionalPayment)) + ' 円'
                  : '-'}
              </Dd>
              <Dd>{insertCommaDelimiter(String(claimDetail.subTotal))} 円</Dd>
              <Dd>{crop(claimDetail.remark ?? '', 10)}</Dd>
              <Dd>
                {isEditable(claim) && (
                  <Edit
                    onClick={() => {
                      setCurrentClaimDetail(claimDetail);
                      setEditModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </Edit>
                )}
              </Dd>
              <Dd
                className="delete"
                onClick={() => {
                  if (!isEditable(claim)) return false;
                  setCurrentClaimDetail(claimDetail);
                  setDeleteModalOpen(true);
                }}
              >
                {isEditable(claim) && (
                  <Edit>
                    <DeleteIcon />
                  </Edit>
                )}
              </Dd>
            </Dl>
          );
        })}
      </TableWrapper>
    </TableContainer>
  );
};

const EditIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2171_5372" maskUnits="userSpaceOnUse" x="1" y="2" width="17" height="17">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 2.5H1V18.5H18V11.5H9V2.5Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2171_5372)">
        <rect
          x="3"
          y="4.5"
          width="12"
          height="12"
          stroke="#EB0000"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M7.3335 12.5H9.94303L16.793 5.64998C17.1391 5.30394 17.3335 4.8346 17.3335 4.34522C17.3335 3.85584 17.1391 3.3865 16.793 3.04045C16.447 2.69441 15.9777 2.5 15.4883 2.5C14.9989 2.5 14.5296 2.69441 14.1835 3.04045L7.3335 9.89047V12.5Z"
        stroke="#EB0000"
        stroke-width="1.096"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5308 3.69287L16.1403 6.3024"
        stroke="#EB0000"
        stroke-width="1.096"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6H16V14C16 16.2091 14.2091 18 12 18H8C5.79086 18 4 16.2091 4 14V6Z"
        stroke="#EB0000"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 6H13V4C13 3.44772 12.5523 3 12 3H8C7.44772 3 7 3.44772 7 4V6Z"
        stroke="#EB0000"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 6H17"
        stroke="#EB0000"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 9L8 14"
        stroke="#EB0000"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 9L12 14"
        stroke="#EB0000"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const TableContainer = styled.div<{ displayScrollHint: boolean }>`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  position: relative;

  ${(props) =>
    props.displayScrollHint &&
    `
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 126px;
      max-height: 70%;
      margin: auto;
      background: url(${ScrollHint}) center / contain no-repeat;
      position: absolute;
      top: 15%;
      left: 0;
    }
  `}

  ${media.lessThan('medium')`
    margin: 1rem -1rem 0;
    padding: 0 1rem;
    border-top: none;
  `}
`;
const TableWrapper = styled.div`
  width: 76rem;

  ${media.lessThan('medium')`
    padding: .5rem;
    border: 1px solid rgba(0,0,0,.36);
    border-radius: .125rem;
  `}
`;
const Dl = styled.dl`
  display: grid;
  grid-template-columns: 7.5rem 6.25rem 6rem 7rem 10.5rem 7rem 7rem 5.25rem 5rem 8.25rem 3.5rem 3.5rem;

  &:nth-of-type(2n) {
    background: rgba(0, 0, 0, 0.05);
  }
`;
const Dt = styled.dt`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  line-height: 1.25rem;

  &:nth-last-of-type(n + 2) {
    border-right: 1px solid rgba(70, 52, 52, 0.1);
  }

  span {
    font-size: inherit;
  }

  .tax_included_annotation {
    font-size: 0.8rem;
    font-weight: normal;
  }
`;
const Dd = styled.dd`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;

  &:nth-last-of-type(n + 2) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
const Edit = styled.p`
  width: 100%;
  cursor: pointer;

  svg {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 auto;
  }
`;
const EmptyContent = styled(EmptyBlock)`
  margin-top: 1rem;
`;
